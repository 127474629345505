@use '@primavera/themes/_pri-theme';
@use '@primavera/themes/_pri-media';

@import url('https://cdn3.devexpress.com/jslib/21.2.8/css/dx.common.css');
@import url('https://cdn3.devexpress.com/jslib/21.2.8/css/dx.material.teal.light.css');

// CUSTOM FONT

//@import './poppins.scss';
//$vk-font-stack: 'Poppins',
//pri-theme.$font-primary;
@import '@primavera/themes/styles/pri-icons';


/*
body,
.mat-tab-label,
.mat-tab-link,
.mat-select-search-inner input,
.mat-tab-group,
.dx-widget,
pri-alert-bar body .body {
  //font-family: $vk-font-stack !important;
}*/

.k-widget ::selection {
  background-color: pri-theme.$theme-100 !important;
}

.k-grid-header .k-i-sort-asc-sm,
.k-grid-header .k-i-sort-desc-sm,
.k-grid-header .k-sort-order {
  color: pri-theme.$theme-100 !important;
}


/* COMPANY AND ORGANIZATION COLOR COLOR CHANGE */

.pri-expander-header.col-md-12,
.pri-row-header {
  width: 100%;

  @include pri-media.media-breakpoint_lg_min {
    width: 66.3%;
  }
}


/* BS DATEPICKER */

.vk-bs-datepicker {
  border: 1px solid pri-theme.$grey-4;
  background: pri-theme.$grey-3;
  box-shadow: none !important;

  .bs-datepicker-head button:hover {
    background-color: transparent;
  }

  .bs-datepicker-container {
    padding: 10px;
    background-color: pri-theme.$grey-1;
  }

  .bs-datepicker-body table td:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month) {
    color: pri-theme.$grey-7;
  }

  .bs-datepicker-head {
    background-color: transparent !important;

    button.previous {
      &:disabled {
        visibility: hidden !important;
      }
    }
  }

  .bs-datepicker-head button {
    color: pri-theme.$grey-8;
    background-color: pri-theme.$grey-1;
    font-weight: 700 !important;
  }

  .bs-datepicker-head {
    height: 40px !important;
    padding: 5px !important;
  }

  .bs-datepicker-body table td span.selected,
  .bs-datepicker-body table td.selected span,
  .bs-datepicker-body table td span[class*='select-']::after,
  .bs-datepicker-body table td[class*='select-'] span::after {
    background: pri-theme.$theme-100 !important;
    color: pri-theme.$grey-8 !important;
    text-decoration: none !important;
  }

  .bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
  .bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
    background: pri-theme.$theme-80 !important;
    color: pri-theme.$grey-8 !important;
  }

  .bs-datepicker-body table td span.is-other-month,
  .bs-datepicker-body table td.is-other-month span {
    visibility: hidden;
  }

  .bs-datepicker-body table td span.disabled,
  .bs-datepicker-body table td.disabled span {
    color: pri-theme.$grey-5;
  }

  .bs-datepicker-body table.days span {
    width: 37px;
    height: 37px;
    line-height: 36px;
  }

  .bs-datepicker-head button.current {
    padding: 0 13px;
  }

  .bs-datepicker-body table th {
    width: 40px;
    width: 35px !important;
    font-weight: 700;
    color: pri-theme.$grey-8;
    margin: 5px;
  }

  .bs-datepicker-body {
    border: 0 none;
    padding-top: 0;
  }
}


/* VK PLANNER */

ngb-modal-backdrop {
  &.show {
    z-index: inherit !important;
  }
}


/* VK MODAL */
.pri-connection-modal .connection-wrapper .connection-description {
  height: auto !important;
}

.vk-modal {
  .pri-editor-view {
    margin-bottom: 0 !important;
  }

  .vk-wrapper-label {
    padding-bottom: 10px !important;

    &:last-child,
    &:first-child {
      padding-bottom: 0px !important;
    }

    label {
      font-size: 12px;
      //font-weight: 600 !important;

      &.vk-state {
        font-size: 17px;
        padding-top: 15px;
      }
    }

    textarea {
      resize: none;

    }
  }
}

/* DASHBOARD LIST ASSET ICONS*/
.shell-widget-main-container pri-table-widget .grid-button-link-content .grid-button-link {
  max-height: 35px;
}

.dashboard-body .row .col-xs-12:nth-child(2) .table-container .table-cell.col-0 {
  padding: 0;
  max-width: 20px;

  .table-cell-to-widget {
    text-align: center;
    width: 100%;
    text-align: center;
    font-family: 'pollen' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    //Better Font Rendering ===========
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
  }
}


/* ListViewer ASSET ICONS*/
pri-datagrid-component {
  pri-datagrid-cell.percentage .datagrid-cell-container .template-container {
    font-family: 'pollen';
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  pri-datagrid-group-header.percentage .header-value {
    font-family: 'pollen';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}


/* VK Logo */

pri-topbar .navbar .left .menu-logo-container .logo {
  width: 130px;
  height: 30px;
}


/* Menu Fill icons [RN] */




/* VK Task selection */

#row_WorkDetail pri-field-wrapper .pri-field.WebComponent-field {
  margin-bottom: 0 !important;
  border-bottom: 0 !important;
  background: transparent;
}

.detail_WebComponent div.form-control.form-control-view {
  padding: 0 !important;
}

/* GRID PREVENTIVE MAINITENANCE PICKER */

#prevMaintManagementLinesGrid #labelselected .help-tooltip-trigger {
  display: none;
}


/* QUICK GUIDE */

#help-page>.description .description-container {
  .logo-menu-opened {
    background-size: 60px;
    width: 60px;
    background-image: url(/assets/images/logo_icon.png);
  }

  .logo-menu-opened:after {
    width: 60px;
  }
}

//Product News
pri-product-news {
  .product-news-card {
    border-radius: 0;

    svg {
      margin-top: 50px;
      display: none;
    }

    &:before {
      font-family: 'pollen' !important;
      width: 50px;
      height: 50px;
      content: '\e64f';
      position: absolute;
      font-size: 40px;
      top: 15px;
      left: -webkit-calc(50% - 25px);
      color: pri-theme.$theme-100;
    }

    .btn-primary {
      border-radius: 0 !important;
      background-color: pri-theme.$theme-100;
      border-color: pri-theme.$theme-100;
    }

    .btn-link {
      color: pri-theme.$theme-100;
    }
  }
}

.card-container {
  &.success {
    border-left-color: pri-theme.$success-100;
  }

  &.info {
    border-left-color: pri-theme.$theme-100 !important;
  }

  &.warning {
    border-left-color: pri-theme.$warning-100;
  }

  &.error {
    border-left-color: pri-theme.$error-100;
  }
}


// Responsive
@include pri-media.media-breakpoint_md_min {
  .pri-modal.vk-modal .pri-modal-content {
    height: calc(100% - (55px * 2 + 20px));
  }

  pri-topbar .navbar .left .menu-logo-container .logo {
    height: 50px;
  }

  .pri-editor-view {
    .pri-sec-actions-phone .btn-group.dropup {
      display: none;
    }

    .pri-sec-actions .btn-group {
      display: inline-block !important;
    }
  }
}

// BUG Fix ToopTip ?
.pri-table-flex .table-flex-row.table-flex-row-header .flex-item span.flex-item-header-title {
  max-width: 160px;
}

// Corregir o bug do modal.  Riade; task: 174814
.form-horizontal:not(.form-loading) .pri-column-required,
.pri-column-validation-error {

  .pri-column-validation-error-border-top,
  .pri-column-validation-error-border-right,
  .pri-column-validation-error-border-bottom,
  .pri-column-validation-error-border-left {
    z-index: 0; // changed because of modal.scss .modal z-index:auto
  }
}

pri-messages-widget .messages-widget {
  background-color: pri-theme.$grey-2;
}

// //Top bar Search
// pri-search {
//   .search-wrapper {
//     background-color: pri-theme.$black;

//     .search-icon:before,
//     .search-input input::-webkit-input-placeholder {
//       color: pri-theme.$white;
//     }
//   }
// }


bs-datepicker-container.top.ng-star-inserted {
  z-index: 1043;
  background-color: pri-theme.$grey-8;
}

.modal-lg {
  width: auto !important;
}

pri-contextbar .contextbar>.wrapper .title .title-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 28px 0 22px;
}

pri-panel-router-wrapper .pri-table-flex .actions-header .selection-info {
  display: none;
}

// BUG Fix GPT Trainer Button
#gpt-trainer-window-button {
  bottom: 3rem !important;
}

// Asset tree button
.pri-editor-view {
  .pri-field:has(vk-asset-tree-button) {
      border-bottom: none;
  }
}
// Get API credentials Info 
.pri-editor-view {
  .getApiCredentialsInfo {
    div.pri-field.Memo-field {
      border-bottom: none;
    }
  }
}
// Asset tree explorer
vk-asset-tree-explorer {
  pri-tree {
    padding-left: 0 !important;
    position: relative !important;
  }

  pri-tree .tree-wrapper {
    padding-left: 5px !important;
    position: absolute !important;
  }

  pri-tree .tree-wrapper #main.cdk-drop-list .node-item .node-container {
    padding-left: 0 !important;
  }
}
